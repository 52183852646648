<template>
	<v-container fluid class="mx-0 px-0">
		<v-tabs v-model="tab" class="mb-4" color="primary">
			<v-tab
				key="searchFilters"
				href="#searchFilters"
				style="font-size: 16px"
				aria-label="Nova Pesquisa"
			>
				<v-icon dense class="mr-2">
					fas fa-search
				</v-icon>
				Nova Pesquisa
			</v-tab>
		</v-tabs>

		<v-tabs-items
			style="border-radius: 5px"
			v-model="tab"
			grow>
			<v-tab-item key="searchFilters" value="searchFilters">
				<v-row v-show="!overlay" class="mt-2 text--primary">
					<v-col cols="12" sm="6" md="4" lg="3" class="py-0">
						<label>Grupo Financeiro</label>
						<v-autocomplete
							v-model="filterReportAutomation.financialGroupId"
							class="mt-1"
							placeholder="Selecione"
							item-text="name"
							item-value="id"
							outlined
							dense
							color="textPrimary"
							item-color="textPrimary"
							:items="financialGroups"
						/>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="3" class="py-0">
						<label>Modelo de Dados</label>
						<v-autocomplete
							v-model="filterReportAutomation.dataModel"
							class="mt-1"
							placeholder="Selecione"
							item-text="name"
							item-value="id"
							outlined
							dense
							color="textPrimary"
							item-color="textPrimary"
							:items="getDataModelTypes"
						/>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="3" class="py-0">
						<label>Layout de Relatório</label>
						<v-autocomplete
							v-model="filterReportAutomation.reportLayout"
							class="mt-1"
							placeholder="Selecione"
							item-text="name"
							item-value="id"
							outlined
							dense
							color="textPrimary"
							item-color="textPrimary"
							:items="getReportLayoutTypesFilter"
						/>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="3" class="py-0">
						<label>Tipo de Integração</label>
						<v-autocomplete
							v-model="filterReportAutomation.reportIntegrationType"
							class="mt-1"
							placeholder="Selecione"
							item-text="name"
							item-value="id"
							outlined
							dense
							color="textPrimary"
							item-color="textPrimary"
							:items="getIntegrationTypes"
						/>
					</v-col>
				</v-row>
				<v-row v-show="overlay" class="primary--text my-15">
					<v-col cols="12" class="d-flex justify-center">
						<v-progress-circular
							color="primary"
							indeterminate
							size="64"
							width="8"
						/>
					</v-col>
					<v-col cols="12" class="d-flex justify-center"> Carregando Filtros </v-col>
				</v-row>
			</v-tab-item>
		</v-tabs-items>
	</v-container>
</template>

<script>
import MovementRecordsMixin from "@/shared/mixins/movementRecords/movementRecordsMixin";
import ReportAutomationMixin from "@/shared/mixins/reportAutomation/reportAutomationMixin"

export default {
	name: "ReportAutomationContentFilter",

	data: () => ({
		overlay: false,
		tab: "searchFilters",
	}),

	mixins: [ MovementRecordsMixin, ReportAutomationMixin ],

	props: {
		financialGroups: {
			type: Array,
			default: () => []
		},
		resetFilterReportAutomation: {
			type: Boolean,
			default: false
		}
	},

	watch: {
		filterReportAutomation: {
			deep: true,
			immediate: true,
			handler(value) {
				if (value) this.$emit("hasFiltersReportAutomation", this.filterReportAutomation)
			}
		},
		resetFilterReportAutomation: {
			deep: true,
			handler(value) {
				if (value) this.clearFilterReportAutomation()
			}
		}
	},

	methods: {
		// clears the page filters object
		clearFilterReportAutomation() {
			this.filterReportAutomation = {
        financialGroupId: '',
        dataModel: '',
        reportLayout: '',
        reportIntegrationType: '',
        receivingDateStart: '',
        receivingDateEnd: '',
      },
			this.$forceUpdate()
			this.$emit("resetStatusFilters", false)
		},
	},
}
</script>
