<template>
  <v-container fluid class="px-5">
    <ViewTitles class="mx-2">
      <h2
        class="primary--text"
        slot="title">
        Automação de Relatórios Cliente
      </h2>
    </ViewTitles>

  <CustomFilter
    :panelIsExpanded="panelExpand"
    :filtersForCheckDate="filtersForCheckDate"
    @expand="panelExpand = $event"
    @handlerClickFilter="handlerClickFilter"
    @clearFilters="clearFilters"
    @openExpandedContent="openExpandedContent">
    <template slot="filters">
      <ReportAutomationContentFilter
        ref="ReportAutomationContentFilter"
        :financialGroups="financialGroups"
        :resetFilterReportAutomation="resetFilterReportAutomation"
        @hasFiltersReportAutomation="hasFiltersReportAutomation"
      />
    </template>
  </CustomFilter>

  <v-row>
    <ReportAutomationTable
      ref="ReportAutomationTable"
      :financialGroups="financialGroups"
    />
  </v-row>

  <v-footer class="my-5">
    <v-row class="d-flex justify-end">
      <v-col cols="12" xs="12" sm="12" md="2" lg="2">
        <v-btn
          x-large
          block
          outlined
          color="textPrimary"
          @click="onClickBack()">
          Voltar
        </v-btn>
      </v-col>
    </v-row>
  </v-footer>
  </v-container>
</template>

<script>
import ReportAutomationContentFilter from '@/components/CustomerReportAutomation/ReportAutomationContentFilter.vue';
import ReportAutomationTable from '@/components/CustomerReportAutomation/ReportAutomationTable.vue';

import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import GenericFunctionsMixin from '@/shared/mixins/genericFunctions/genericFunctionsMixin'

import CustomFilter from '@/components/CustomFilter/CustomFilter.vue';
import ViewTitles from '@/components/PageTitles/ViewTitles.vue';

import { cloneDeep } from 'lodash';

export default {
  components: {
    ViewTitles,
    CustomFilter,
    ReportAutomationContentFilter,
    ReportAutomationTable
  },

  data: () => ({
    isRHProtegido: false,
    panelExpand: false,
    resetFilterReportAutomation: false,

    filtersForCheckDate: {},
    filterReportAutomation: {},
    dataFilterReportAutomation: {},

    financialGroups: [],
  }),

  mixins: [
    VerifyRoutesMixin,
    FinancialGroupsMixin,
    GenericFunctionsMixin
  ],

  methods: {
    // return to the route that has all the menus
    onClickBack() {
      this.redirectRouter('HomeAllMenus', { isRHProtegido: this.isRHProtegido });
    },

    // search data from defined filters
    handlerClickFilter(dates, dateType) {
      if (dates && dates.length > 0) {
        this.processDates(dates, dateType);
      } else {
        this.dates = [];
      }

      const commonFilterProperties = this.setCommonFilterProperties();

      this.handleReportAutomation(commonFilterProperties)
    },

    // set common filters
    setCommonFilterProperties() {
      return {
        isRHProtegido: this.isRHProtegido,
        startDate: this.dates[0],
        endDate: this.dates[1],
        dateType: this.dateType,
      };
    },

    // set object to filter page data
    handleReportAutomation(commonFilterProperties) {
      this.resetFilterReportAutomation = false;

      this.filterReportAutomation = {
        ...this.dataFilterReportAutomation,
        receivingDateStart: this.dates[0],
        receivingDateEnd: this.dates[1],
      }

      this.$refs.ReportAutomationTable.handlerFilter(this.filterReportAutomation, 'click');
    },

    hasFiltersReportAutomation(data, type) {
      this.dataFilterReportAutomation = cloneDeep(data);
    },

    // clear fields from the CustomFilter component
    clearFilters() {
      this.$refs.ReportAutomationContentFilter.clearFilterReportAutomation();
    },

    // function executed only when the "Nenhuma Seleção de Data" option was set in the date filter
    // so the user can set other filters to optimize the search
    openExpandedContent(expand) {
      this.panelExpand = expand
    },
  },

  async created() {
    await this.getFinancialGroups();
    this.isRHProtegido = this.$route.query.isRHProtegido;
  }
};
</script>
