import HttpService from '../HttpService'
import axios from 'axios';

export default class MyReportsService {
  constructor() {
    this._httpServiceReportFilter = new HttpService('/sdi/report-configuration/filter');
    this._httpServiceReportConfig = new HttpService('/sdi/report-configuration');
  }

  // fetch user reports
  async GetReportAutomations(params) {
    return await this._httpServiceReportFilter.get('', params);
  };

  // fetch user report by id
  async GetCustomerReportById(customerReportId) {
    return await this._httpServiceReportConfig.get(`/${customerReportId}`);
  };

  // save form
  async SaveForm(data) {
    return await this._httpServiceReportConfig.post('', data);
  };

  async EditForm(data) {
    return await this._httpServiceReportConfig.put('', data);
  };

  // inactive form
  async InactiveForm(reportId) {
    return await this._httpServiceReportConfig.delete(`/${reportId}`);
  };
}
