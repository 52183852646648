<template>
  <v-list>
    <div v-for="action in sortedActions" :key="action.label">
      <v-list-item v-if="action.condition" :disabled="action.disabled" @click="action.handler">
        <v-list-item-icon class="mx-0 mr-3">
          <v-icon :size="action.iconSize" dense :disabled="action.disabled" :color="action.color">{{ action.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ action.label }}
            <v-progress-circular v-if="action.loadingCountDocuments" :color="action.color" indeterminate size="20" width="2"/>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>
  </v-list>
</template>

<script>
export default {
  props: { actions: Array },

  data() {
    return { sortedActions: [] };
  },

  watch: {
    actions: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.sortedActions = [...val].sort((a, b) => (a.label > b.label ? 1 : -1));
        }
      },
    },
  },
};
</script>
